import { Carousel, Col, Row, Typography as T } from 'antd'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import gI18n from '../../config/i18n'
import { getImageInfo, localizedSlug } from '../../gatsby/NodeHelpers'
import Tags from '../Tags'
import './index.scss'
import ProductItem from './ProductItem'

interface Props {
  products: any
  blog: any
  tags: string[]
  locale: string
}

const BlockSale = ({ products, blog, locale, tags }: Props) => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    draggable: false,
    autoplay: false,
    arrows: true,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  }

  const renderBlog = () => {
    const blogImage = getImageInfo(blog.node.data.body)
    return (
      <div className="sg-view-more-field">
        <div className="sg-content">
          <div className="sg-detail">
            <div className="sg-detail-content">
              <T.Title level={4} ellipsis={{ rows: 2 }}>
                {blog.node.data.title.text}
              </T.Title>
              <T.Paragraph ellipsis={{ rows: 4 }} className="text">
                {blog.node.data.content.text.substr(0, 200)}
              </T.Paragraph>
            </div>
          </div>
          <Link
            to={localizedSlug({ lang: locale, uid: `blog/${blog.node.uid}` })}
            className="sg-overlay-btn"
            title={blog.node.data.title.text}
          >
            <div>{gI18n[locale].details}</div>
          </Link>
          <Img style={{ width: 100 + '%', height: 100 + '%' }} title={blogImage.caption} fluid={blogImage.fluid} />
        </div>
      </div>
    )
  }

  return (
    <Row gutter={[0, 16]}>
      <Col span={24}>
        <Tags type="shop" tags={tags} locale={locale} className="sg-tag-block-sale" />
      </Col>
      <Col span={24}>
        <Row gutter={[16, 16]} justify="center">
          <Col xs={{ span: 24 }} sm={{ span: 6 }}>
            {blog && renderBlog()}
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 18 }}>
            <div className="sg-list-items">
              <Carousel {...settings}>
                {products.map((product: any) => (
                  <ProductItem key={product.node.uid} product={product} locale={locale} />
                ))}
              </Carousel>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default BlockSale
