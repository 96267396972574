import { Typography as T } from 'antd'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import gI18n from '../../config/i18n'
import { getImageInfo, localizedSlug } from '../../gatsby/NodeHelpers'
import Tags from '../Tags'
import './index.scss'

interface Props {
  product: any
  locale: string
  showTag?: boolean
}

const ProductItem = ({ product, locale, showTag }: Props) => {
  const data = product.node.data
  const tags = product.node.tags
  const image = getImageInfo(data.body)
  return (
    <div className="sg-content">
      <Link
        to={localizedSlug({ lang: locale, uid: `shop/${product.node.uid}` })}
        className="sg-link"
      >
        <div className="sg-img">
          <Img style={{ zIndex: -10 }} title={image.caption} fluid={image.fluid} />
        </div>
        {data.market_price > 0 && (
          <div className="sg-label">
            -{Math.floor(((data.market_price - data.sell_price) / data.market_price) * 100)}%
          </div>
        )}
        <div className="sg-detail" title={data.title.text}>
          <T.Title level={4} className="title">
            <T.Paragraph ellipsis={{ rows: 2 }}>{data.title.text}</T.Paragraph>
          </T.Title>
          <div className="price">
            {data.quantity === 0 ? (
              <T.Title level={4} type="danger">
                {gI18n[locale].outofStock}
              </T.Title>
            ) : (
                <>
                  <T.Title level={4} type="danger">
                    {gI18n[locale].moneySign + data.sell_price.toLocaleString(locale)}
                  </T.Title>
                  {data.market_price > 0 && (
                    <T.Text className="old-price">
                      {gI18n[locale].moneySign + data.market_price.toLocaleString(locale)}
                    </T.Text>
                  )}
                </>
              )}
          </div>
          {showTag && (
            <Tags type="shop" tags={tags} locale={locale} className="sg-tag-breadcrumb d-inline-block mt-3" />
          )}
        </div>
      </Link>
    </div>
  )
}

export default ProductItem
